:root {
  --color-black: #0a0a0a;
  --color-white: #fff;
  --color-deep-brown: #664d4d;
  --color-light-brown: #cca9a4;
  --font-primary: Helvetica, sans-serif;
  --font-secondary: Coolvetica, sans-serif;
  --footer-height: 80px;
}

@font-face {
  font-family: Helvetica;
  src: url("HelveticaNeue-Thin.a0e368a4.otf");
  font-weight: 100;
}

@font-face {
  font-family: Helvetica;
  src: url("HelveticaNeue-Roman.e54207d6.otf");
  font-weight: 400;
}

@font-face {
  font-family: Coolvetica;
  src: url("coolvetica-rg.294713b4.otf");
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-black);
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-family: var(--font-primary);
  display: grid;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.container {
  border-radius: 8px;
  width: 100%;
  max-width: 760px;
  margin: auto;
  display: flex;
  position: relative;
}

main.container {
  background-color: var(--color-white);
  margin-top: 120px;
}

#food-pot {
  aspect-ratio: 1;
  width: 180px;
  position: absolute;
  top: -80px;
  right: 120px;
}

.container > section {
  flex-grow: 1;
  padding: 32px 32px 84px;
  position: relative;
  overflow: hidden;
}

iframe {
  border: none;
  outline: none;
}

dotlottie-player#confetti {
  pointer-events: none;
  opacity: 0;
  transition: all .75s ease-in-out;
  position: absolute;
  inset: 0;
}

#banner {
  background-color: var(--color-deep-brown);
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 1000px;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 100;
  line-height: 0;
  display: none;
  position: absolute;
  bottom: 200px;
  right: -300px;
  transform: rotate(-45deg);
}

.container > aside {
  background-color: var(--color-deep-brown);
  border-radius: 0 8px 8px 0;
  justify-content: center;
  align-items: center;
  width: 214px;
  padding: 0 28px;
  display: flex;
}

.container > aside > h2 {
  color: var(--color-light-brown);
  font-size: 82px;
  line-height: 1;
  font-family: var(--font-secondary);
  font-weight: 400;
  transform: rotate(-90deg);
}

.main-header {
  margin-bottom: 50px;
}

.main-header > h1 {
  color: var(--color-deep-brown);
  font-size: 64px;
  line-height: 0;
  font-family: var(--font-secondary);
  font-weight: 400;
}

.main-header > p {
  color: var(--color-deep-brown);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 0;
}

.form-group {
  margin-bottom: 24px;
}

.form-group__message {
  margin-top: 0;
  font-size: 12px;
}

#hourglass {
  animation: 2s ease-in-out infinite forwards spin;
  display: inline-block;
  transform: rotate(0);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.form-group__label {
  margin-bottom: 4px;
  font-size: 16px;
  display: block;
}

.form-group.row {
  align-items: center;
  display: flex;
}

.form-group__input {
  color: var(--color-black);
  border: 1px solid var(--color-black);
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 44px;
  padding: 0 1em;
}

.form-group__input:disabled {
  cursor: not-allowed;
}

input[type="checkbox"] {
  width: auto;
}

select.form-group__input, label.checkbox {
  cursor: pointer;
}

.content-area {
  font-size: 16px;
  line-height: 28px;
}

.main-navigation {
  margin-top: 1em;
}

.main-navigation__list {
  padding-inline-start: 0;
  list-style-type: none;
}

.main-navigation__list__item {
  margin-right: 8px;
  display: inline-block;
}

.main-navigation__list__item a {
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 3em;
  padding: .75em 1.25em;
  font-size: 16px;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

.main-navigation__list__item a:hover, .main-navigation__list__item--active {
  background-color: var(--color-white);
  color: var(--color-black) !important;
}

.main-navigation__list__item--active {
  cursor: not-allowed;
}

button {
  background-color: var(--color-deep-brown);
  color: var(--color-white);
  cursor: pointer;
  font-size: 16px;
  line-height: 0;
  font-family: var(--font-primary);
  border: none;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding: 2em 0;
  font-weight: 400;
}

button:disabled {
  opacity: .75;
  cursor: not-allowed;
}

[data-visible="true"] {
  visibility: visible;
  height: auto;
}

[data-visible="false"] {
  visibility: hidden;
  height: 0;
}

footer {
  color: var(--color-white);
  padding: 24px 0;
  font-size: 16px;
}

.preloader {
  background-color: var(--color-deep-brown);
  color: var(--color-white);
  transform-origin: top;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: transform 1s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(1);
}

.preloader--hide {
  pointer-events: none;
  transform: scaleY(0);
}

@media screen and (width <= 700px) {
  .container {
    flex-direction: column-reverse;
  }

  main.container {
    margin-top: 60px;
  }

  .container > section {
    margin-bottom: 48px;
    padding: 16px;
  }

  .container > aside {
    border-radius: 8px 8px 0 0;
    width: 100%;
  }

  .main-header {
    margin-bottom: 40px;
  }

  .main-header > h1 {
    font-size: 38px;
  }

  .main-header > p {
    margin-top: 36px;
    font-size: 16px;
  }

  .container > aside {
    padding: 24px 0;
  }

  .container > aside > h2 {
    margin: 0;
    font-size: 48px;
    transform: rotate(0);
  }

  .form-group {
    margin-bottom: 16px;
  }

  .form-group__label {
    margin-bottom: 2px;
    font-size: 14px;
  }

  .form-group__input {
    font-size: 14px;
  }

  #food-pot {
    width: 100px;
    top: -40px;
    right: 20px;
  }

  footer {
    padding: 24px 0;
    font-size: 14px;
  }

  #bank-info-form button {
    margin-top: 24px;
  }

  #banner {
    padding: 4px 0;
    font-size: 16px;
  }
}
/*# sourceMappingURL=about.b1b16c5f.css.map */
